'use client';

import { useEffect } from 'react';

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    window['newrelic']?.noticeError?.(error);
  }, [error]);

  return (
    <div className="prose max-w-none text-center py-12 container-lg">
      <h1>Error</h1>
      <p>
        Unfortunately an unexpected error has occurred. We have been notified and will deem to
        resolve it as early as possible.
      </p>
    </div>
  );
}
